const questions = [
    {
        q: 'Какой лучший способ похудеть на ваш взгляд?',
        c: 4,
        v: [
            'Бег натощак',
            'Круговые тренировки с отягощениями',
            'Многочасовые тренировки с большими весами',
            'Тренировки на велотренажере умеренной интенсивности, но большой продолжительности',
        ]
    },
    {
        q: 'Можно ли тренироваться во время острой респираторной вирусной инфекции (простуды)?',
        c: 2,
        v: [
            'Да, если нет температуры.',
            'Можно тренироваться во время болезни, но это затянет выздоровление.',
            'Нет и ещё 2 недели после выздоровления, иначе будут осложнения.',
        ]
    },
    {
        q: 'Врач сказал, что у меня низкий гемоглобин, мог ли гемоглобин упасть из-за тренировок?',
        c: 3,
        v: [
            'Нет, такого не может быть гемоглобин и тренировки никак не связаны',
            'Нет, этого не может быть, тренировки, особенно на выносливость, повышают гемоглобин.',
            'Да, такое могло случиться, особенно если вы много бегаете.',
        ]
    },
    {
        q: 'После тренировки заболело плечо, после следующей тренировки боль усилилась, как должен поступить тренер?',
        c: 1,
        v: [
            'Исключить болезненные движения и продолжить тренировки',
            'Полностью отказаться от всех тренировок, ведь только полный отказ позволит быстрее поправиться.',
            'Начать закачивать плечо, мышцы окрепнут и боль пройдёт',
        ]
    },
    {
        q: 'Со скольких лет детям можно тренироваться в тренажерном зале?',
        c: 3,
        v: [
            'Тренажерный зал недопустим до 18ти лет',
            'Есть смысл тренироваться лет с 12, когда уровень половых гормонов достаточен',
            'Силовые тренировки допустимы с 7-8 лет, если ребенок способен правильно выполнять указания тренера',
        ]
    },
    {
        q: 'Как насчёт тренировки во время менструации?',
        c: 3,
        v: [
            'Недопустимы, так как увеличат время и интенсивность менструации до кровотечения',
            'Тренировки во время менструации должны быть интенсивнее обычных так как высокий прогестерона в это время способствует мышечному росту.',
            'Тренировки возможны со 2-3 дня, но необходимо снизить интенсивность',
            'Тренироваться можно, но нагрузка разрешена только на верхний плечевой пояс',
        ]
    },
    {
        q: 'Близорукость средней степени, можно ли мне тренироваться в зале с отягощениями?',
        c: 1,
        v: [
            'Необходимо обследовать сетчатку, и если с ней все хорошо, то можно тренироваться',
            'Да, можно. Есть много примеров удачных спортсменов с близорукостью',
            'Нет, нельзя. С близостью (любой степени) тяжести поднимать запрещено',
        ]
    },
    {
        q: 'Для, того чтобы похудеть, нужно обязательно начинать день с плотного завтрака, это действительно так?',
        c: 3,
        v: [
            'Да, без плотного завтрака снижается метаболизм и похудеть практически невозможно',
            'Достаточно сделать как французы, выпить кофе с круасаном',
            'Для большинства людей завтрак не физиологичен, и они, просто зря себя насилуют, пытаясь вести здоровый образ жизни',
        ]
    },
    {
        q: 'Тренер сказал купить бета-аланин, что это? Мне не навредит?',
        c: 1,
        v: [
            'Бета-аланин, увеличивает амплитуду мышечного сокращения и часто используется в качестве предтренировочного средства.',
            'Бета-аланин, нужен для рельефа и жиросжигания.',
            'Бета-аланин, нужен для наращивания мышечной массы',
        ]
    },
    {
        q: 'Друг порекомендовал принимать концентрат сывороточного белка, после первого приема живот вздулся начал урчать, был однократный жидкий стул. В чем же вероятная причина моей оказии?))',
        c: 2,
        v: [
            'Скорее всего в магазине продали просроченный товар, а дату выпуска изменили.',
            'Вероятнее всего это лактазная недостаточность, ведь многие взрослые люди плохо переносят молочные продукты.',
            'Скорее всего это аллергия на белок коровьего молока',
            'На складе нарушили условия хранения товара, ведь белок очень быстро портится если его хранить в тепле (денатурирует)',
        ]
    },
];

(function($) {
    var questionNumber = 1;
    var correctAnswers = 0;

    $('.question .btn-light').on('click', function(e) {
        e.preventDefault();
        var _this = $(this);
        if (!_this.closest('.question').hasClass('done')) {
            if (!_this.hasClass('correct')) {
                _this.addClass('error');
            } else {
                correctAnswers++;
            }
            _this.closest('.question').addClass('done');
        }
    });

    $('.question .btn-primary').on('click', function(e) {
        e.preventDefault();
        if ($(this).closest('.question').hasClass('done')) {
            $('.question').hide();
            questionNumber++;
            $('#q' + questionNumber).show();
            $('#congratulations').html('Поздравляем! Вы правильно ответили на ' + correctAnswers + ' из 10 вопросов');

            if (+correctAnswers === 10) {
                $('#congratulations-10').show();
            }
            if (+correctAnswers >= 6 && +correctAnswers < 10) {
                $('#congratulations-6').show();
            }
            if (+correctAnswers <= 5) {
                $('#congratulations-5').show();
            }
        }
    });

    /* Article FructCode.com */
    $( document ).ready(function() {
        $("#quiz-form-submit").click(
            function(){
                sendAjaxForm('result_form', 'ajax_form', 'call.php');
                return false;
            }
        );
    });

    function sendAjaxForm(result_form, ajax_form, url) {
        $.ajax({
            url:     url, //url страницы (action_ajax_form.php)
            type:     "POST", //метод отправки
            dataType: "html", //формат данных
            data: $("#"+ajax_form).serialize(),  // Сеарилизуем объект
            success: function(response) { //Данные отправлены успешно
                result = $.parseJSON(response);
                $('#quiz-form').hide();
                $('#result_form').html('<h2>Сообщение успешно отправлено.</h2><h3>В ближайшее время мы с вами свяжемся</h3>');
            },
            error: function(response) { // Данные не отправлены
                $('#quiz-form').hide();
                $('#result_form').html('<h2>Ошибка при отправке сообщения</h2>');
            }
        });
    }

    $("#form-submit").click(
        function(){
            sendAjaxForm2('result_form_offer', 'ajax_form_offer', 'form2.php');
            return false;
        }
    );

    function sendAjaxForm2(result_form, ajax_form, url) {
        $.ajax({
            url:     url, //url страницы (action_ajax_form.php)
            type:     "POST", //метод отправки
            dataType: "html", //формат данных
            data: $("#"+ajax_form).serialize(),  // Сеарилизуем объект
            success: function(response) { //Данные отправлены успешно
                result = $.parseJSON(response);
                $('#ajax_form_offer').hide();
                $('#result_form_offer').html('<h2>Сообщение успешно отправлено.</h2><h3>В ближайшее время мы с вами свяжемся</h3>');
            },
            error: function(response) { // Данные не отправлены
                $('#ajax_form_offer').hide();
                $('#result_form_offer').html('<h2>Ошибка при отправке сообщения</h2>');
            }
        });
    }


})(jQuery);

function showPopup(id) {
    $(`.popup[data-id=${id}]`).addClass('show');
    $('body').addClass('popup-visible');
}

$('#start-quiz').on('click', function(e) {
    e.preventDefault();
    showPopup('quiz');
});