import jQuery from 'jquery';
import './vendor/jquery.easing.min';
import './vendor/bootstrap.bundle.min';
window.$ = window.jQuery = jQuery;


$('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        $(this).closest('.popup').removeClass('show');
        $('body').removeClass('popup-visible');
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            $('html, body').animate({
                scrollTop: (target.offset().top - 65)
            }, 1000, "easeInOutExpo");
            return false;
        }
    }
});

$('.js-scroll-trigger').on('click', function() {
    $('.js-scroll-trigger').each(function(i, val){
        $(val).removeClass('active');
    });
    $(this).addClass('active');
    $('#menu').removeClass('show');
});
