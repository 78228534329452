import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import Siema from 'siema';
import './scroll-nav';
import './quiz';
import 'owl.carousel';


const slider = new Siema({
    selector: '.siema',
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    multipleDrag: true,
    threshold: 20,
    loop: true,
    rtl: false,
    onInit: () => {},
    onChange: () => {},
});

/*const sliderReviews = new Siema({
    selector: '.siema-reviews',
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    multipleDrag: true,
    threshold: 20,
    loop: true,
    rtl: false,
    onInit: () => {},
    onChange: () => {},
});*/

$(document).ready(function(){
    $('.owl-carousel').owlCarousel({
        loop:true,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            845:{
                items:3,
                nav:false
            }
        }
    });
});

Array.from(document.getElementsByClassName("prev")).forEach(function(element) {
    element.addEventListener('click', (e) => {
        e.preventDefault();
        slider.prev()
    });
});

Array.from(document.getElementsByClassName("next")).forEach(function(element) {
    element.addEventListener('click', (e) => {
        e.preventDefault();
        slider.next()
    });
});


window.addEventListener('scroll', function() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        $('#header').addClass('scrolled');
    } else {
        $('#header').removeClass('scrolled');
    }
});

$('#menu-toggle').on('click', function() {
    $('#menu').addClass('show');
});

$('#menu-close').on('click', function() {
    $('#menu').removeClass('show');
});

$('.staff_wrapper .prev-arrow').on('click', function() {

    const $s1 = $(".s1");
    const $s2 = $(".s2");
    const $s3 = $(".s3");
    const $s4 = $(".s4");

    if ($(window).width() <= 884) {
        $s2.addClass('remove-arrow');
        $( ".s1" ).animate({
            opacity: 0.25,
            top: "-=2000",
        }, 500, function() {
            $s2.removeClass('remove-arrow');
            $s1.removeClass('s1').addClass('s4');
            $s2.removeClass('s2').addClass('s1');
            $s3.removeClass('s3').addClass('s2');
            $s4.removeClass('s4').addClass('s3');
            $(".s1, .s2, .s3, .s4").removeAttr("style");
            $(".s2 .content").removeAttr("style");
            $(".s3 .content").removeAttr("style");
            $(".s4 .content").removeAttr("style");
            $(".s2 .image").removeAttr("style");
            $(".s3 .image").removeAttr("style");
            $(".s4 .image").removeAttr("style");
        });
        // const wid = ($('.staff_wrapper').width() - $('.s1').width()) / 2;
        $( ".s2, .s3, .s4" ).animate({
            top: `-=53`,
        }, 500, function() {
            // Animation complete.
        });
        $( ".s2 .content, .s2 .image" ).animate({
            opacity: 1,
        }, 500, function() {
            // Animation complete.
        });
    } else {
        $( ".s1" ).animate({
            opacity: 0.25,
            left: "-=3000",
        }, 500, function() {
            $s1.removeClass('s1').addClass('s4');
            $s2.removeClass('s2').addClass('s1');
            $s3.removeClass('s3').addClass('s2');
            $s4.removeClass('s4').addClass('s3');
            $(".s1, .s2, .s3, .s4").removeAttr("style");
        });
        const wid = ($('.staff_wrapper').width() - $('.s1').width()) / 3;
        $( ".s2, .s3, .s4" ).animate({
            left: `-=${wid}`,
        }, 500, function() {
            // Animation complete.
        });
    }
});

function showPopup(id) {
    $(`.popup[data-id=${id}]`).addClass('show');
    $('body').addClass('popup-visible');
}

$('.popup .close').on('click', function(){
    $(this).closest('.popup').removeClass('show');
    $('body').removeClass('popup-visible');
    $('input[name=name]').removeClass('error');
    $('input[name=phone]').removeClass('error');
});

$('.call-request').on('click', function (e) {
    e.preventDefault();
    showPopup('call');
})

$('#staff1').on('click', function(e) {
    e.preventDefault();
    showPopup('staff1');
});
$('#staff2').on('click', function(e) {
    e.preventDefault();
    showPopup('staff2');
});
$('#staff3').on('click', function(e) {
    e.preventDefault();
    showPopup('staff3');
});
$('#staff4').on('click', function(e) {
    e.preventDefault();
    showPopup('staff4');
});

document.querySelector('.ajax-form').addEventListener('submit', function(e) {
    e.preventDefault();
    $('input[name=name]').removeClass('error');
    $('input[name=phone]').removeClass('error');
    let formData = new FormData(this);
    let parsedData = {};
    for(let name of formData) {
        if (typeof(parsedData[name[0]]) == "undefined") {
            let tempdata = formData.getAll(name[0]);
            if (tempdata.length > 1) {
                parsedData[name[0]] = tempdata;
            } else {
                parsedData[name[0]] = tempdata[0];
            }
        }
    }

    if (!parsedData.name) {
        $('input[name=name]').addClass('error');
    }
    if (!parsedData.phone) {
        $('input[name=phone]').addClass('error');
    }
    if (!parsedData.phone || !parsedData.name) {
        return;
    }

    let options = {};
    switch (this.method.toLowerCase()) {
        case 'post':
            options.body = JSON.stringify(parsedData);
        case 'get':
            options.method = this.method;
            options.headers = {'Content-Type': 'application/json'};
            break;
    }

    fetch(this.action, options).then(r => r.json()).then(data => {
        console.log(data);
        if (data.code === 0) {
            $(this).html('<h2 class="heading">Успешно отправлено. Мы вам перезвоним</h2>');
        }
    });
});

document.querySelector('.ajax-form-quiz').addEventListener('submit', function(e) {
    e.preventDefault();
    $('input[name=name]').removeClass('error');
    $('input[name=phone]').removeClass('error');
    let formData = new FormData(this);
    let parsedData = {};
    for(let name of formData) {
        if (typeof(parsedData[name[0]]) == "undefined") {
            let tempdata = formData.getAll(name[0]);
            if (tempdata.length > 1) {
                parsedData[name[0]] = tempdata;
            } else {
                parsedData[name[0]] = tempdata[0];
            }
        }
    }

    if (!parsedData.name) {
        $('input[name=name]').addClass('error');
    }
    if (!parsedData.phone) {
        $('input[name=phone]').addClass('error');
    }
    if (!parsedData.phone || !parsedData.name) {
        return;
    }

    let options = {};
    switch (this.method.toLowerCase()) {
        case 'post':
            options.body = JSON.stringify(parsedData);
        case 'get':
            options.method = this.method;
            options.headers = {'Content-Type': 'application/json'};
            break;
    }

    fetch(this.action, options).then(r => r.json()).then(data => {
        console.log(data);
        if (data.code === 0) {
            $(this).html('<h2 class="heading">Успешно отправлено. Мы вам перезвоним</h2>');
        }
    });
});

$('#show-more').on('click', function(e){
    e.preventDefault();
    $('.why-text').addClass('show');
});

$('#show-less').on('click', function(e){
    e.preventDefault();
    $('.why-text').removeClass('show');
});